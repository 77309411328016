<!-- 最左边的选择框 -->
<template>
  <div class="mycard">
    <header>
      <el-popover
        placement="right"
        width="300"
        trigger="click"
      >
        <div class="friendInfo">
          <div
            class="esInfo"
            v-if="user.user_info"
          >
            <div class="left">
              <div class="people">
                <div class="nickname">{{ user.user_info.nickname }}</div>
                <div :class="[user.user_info.sex===1?'gender-male':'gender-female']"></div>
              </div>
              <div class="signature">{{ user.user_info.signature }}</div>
            </div>
            <div class="right">
              <img
                class="avatar"
                width="60"
                height="60"
                :src="user.img"
              >
            </div>
          </div>
          <div
            v-if="user.user_info"
            class="detInfo"
          >
            <div class="list_id"><span>微信号</span><span>{{ user.user_info.username }}</span></div>
          </div>
        </div>
        <img
          slot="reference"
          :src="user.img"
          class="avatar"
        >
      </el-popover>
    </header>
    <div
      class="navbar"
      @click="clearSearch"
    >
      <p
        v-if="unreadsum > 0"
        class="unread"
      >{{ unreadsum }}</p>
      <p
        v-if="user.new_friend_tips_num > 0"
        class="unread newFriend"
      >{{ user.new_friend_tips_num }}</p>
      <router-link
        to="/chat"
        class="icon iconfont icon-msg"
      ></router-link>
      <router-link
        to="/friend"
        class="icon iconfont icon-friend"
      ></router-link>
      <router-link
        to="/my"
        class="icon iconfont icon-collection"
      ></router-link>
    </div>
    <footer>
      <el-dropdown
        placement="top"
        @command="handleCommand"
      >
        <i
          class="icon iconfont icon-more"
          style="margin-top: 0;"
        ></i>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          <el-dropdown-item command="reset">修改密码</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </footer>
    <el-dialog
      title="密码修改"
      :visible.sync="dialogVisible"
      width="500px"
      :close-on-click-modal="false"
    >
      <el-form :model="formData">
        <el-form-item
          label="输入原密码"
          label-width="60"
        >
          <el-input
            v-model="formData.pass1"
            autocomplete="off"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item
          label="输入新密码"
          label-width="60"
        >
          <el-input
            v-model="formData.pass2"
            autocomplete="off"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item
          label="确认新密码"
          label-width="60"
        >
          <el-input
            v-model="formData.pass3"
            autocomplete="off"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="handleReset"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { clearStore } from '@/utils/store'
import { resetPassword } from '@/api'
export default {
  data () {
    return {
      formData: {
        pass1: '',
        pass2: '',
        pass3: ''
      },
      hasToken: false,
      dialogVisible: false
    }
  },
  computed: {
    ...mapState([
      'user',
      'unreadsum'
    ])
  },
  mounted () {
    console.log(this.user)
  },
  methods: {
    clearSearch () {
      this.$store.dispatch('search', '')
    },
    handleCommand (command) {
      if (command == 'reset') {
        this.dialogVisible = true
      } else {
        clearStore()
        this.$router.push('/login')
      }
    },
    handleReset () {
      if (!this.formData.pass1 || this.formData.pass1.length < 6) {
        this.$message.error('请输入原密码,不能小于6位')
        return;
      }
      if (!this.formData.pass2 || this.formData.pass2.length < 6) {
        this.$message.error('请输入新密码,不能小于6位')
        return;
      }
      if (!this.formData.pass3 || this.formData.pass3.length < 6) {
        this.$message.error('请确认新密码,不能小于6位')
        return;
      }
      if (this.formData.pass2 !== this.formData.pass3) {
        this.$message.error('两次新密码不一致')
        return;
      }
      resetPassword(this.formData).then(res => {
        this.$message.success('已修改,请重新登陆')
        setTimeout(() => {
          this.handleCommand()
        }, 1000);
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/fonts/iconfont.css'
.friendInfo
  padding 0 30px
.esInfo
  display flex
  align-items center
  padding 20px 0 20px 0
  .left
    flex 1
    .people
      .nickname
        display inline-block
        font-size 20px
        margin-bottom 16px
      .gender-male, .gender-female
        display inline-block
        width 18px
        height 18px
        vertical-align top
        margin-top 2px
    .signature
      font-size 14px
      color rgba(153, 153, 153, 0.8)
  .right
    .avatar
      border-radius 3px
.tip
  font-size 24px
  float right
  cursor pointer
.detInfo
  padding 20px 0 10px
  border-top 1px solid #e7e7e7
  .remark, .area, .list_id
    font-size 14px
    margin-top 20px
    span
      font-size 14px
      color rgba(153, 153, 153, 0.8)
      margin-right 40px
      &:last-child
        margin-right 0
  .remark
    margin-top 0
.router-link-active
  color #00dc41 !important
.mycard
  position relative
  width 100%
  height 100%
  .avatar
    width 36px
    height 36px
    margin 20px 12px 0 12px
    border-radius 2px
  .navbar
    width 100%
    text-align center
  .icon
    display inline-block
    font-size 26px
    margin-top 28px
    padding 0 16px
    box-sizing border-box
    color rgb(173, 174, 175)
    opacity 0.8
    cursor pointer
    &.active
      color rgb(0, 220, 65)
    &:hover
      opacity 1
  .icon-msg, .icon-more
    font-size 22px
  .icon-msg
    padding 0 19px
.unread
  position absolute
  height 15px
  background-color rgb(225, 0, 0)
  border-radius 50px
  color rgb(225, 225, 225)
  font-size 14px
  top 75px
  left 66%
  transform translateX(-50%)
  z-index 99
  box-sizing border-box
  padding 0 7px
  &.newFriend
    top 130px
footer
  position absolute
  bottom 20px
  width 100%
  text-align center
</style>
