<template>
  <div id="app">
    <div
      v-if="showSide"
      class="sidebar"
    >
      <mycard></mycard>
    </div>
    <div class="main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import mycard from './components/mycard/mycard'
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/reset.css'

export default {
  name: 'App',
  components: {
    mycard
  },
  data () {
    return {
      showSide: false
    }
  },
  watch: {
    $route (route) {
      this.showSide = route.meta && route.meta.showSide
    }
  },
  mounted () {
    Notification.requestPermission()
  }
};
</script>

<style lang="less">
html,
body {
  overflow: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
body {
  font-size: 16px;
  background-color: #eee;
  -webkit-font-smoothing: antialiased;
}
#app {
  width: 860px;
  height: 600px;
  background-color: #fff;
  display: flex;
}
</style>
<style lang="stylus" scoped>
.sidebar
  width 60px
  background #2b2c2f
.main
  flex 1
</style>
