import store from '../store'
export let websock = null
import { fetchUserInfo } from '@/api'

let isActive = true
var hiddenProperty = 'hidden' in document ? 'hidden' :
  'webkitHidden' in document ? 'webkitHidden' :
    'mozHidden' in document ? 'mozHidden' :
      null;
var visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange');
var onVisibilityChange = function () {
  if (!document[hiddenProperty]) {
    console.log('页面激活');
    isActive = true
  } else {
    isActive = false
    console.log('页面非激活')
  }
}
document.addEventListener(visibilityChangeEvent, onVisibilityChange);
const { wsUrl, photoUrl, openJudge } = window.__gconf
const websocketonmessage = (e) => {
  const ev = JSON.parse(e.data)
  console.log(ev)
  if (ev.action === 'chatData') {
    if (store.state.user.name !== ev.data.data.msg.user_info.name) {
      let listIds = store.state.chatlist.map(item => {
        return item.list_id
      })
      const el = document.getElementById('chatmp3')
      el.play()
      console.log(ev.data)
      if ((openJudge && !isActive) || !openJudge) {
        Notification.requestPermission()
        new Notification('消息通知', {
          body: '你有新的消息待查看'
        })
      }
      if (listIds.includes(ev.data.list_id)) {
        const _chatlist = [...store.state.chatlist]
        for (let i = 0; i < _chatlist.length; i++) {
          if (store.state.selectId !== ev.data.list_id) {
            _chatlist[i].unread += 1
          }
          if (_chatlist[i].list_id === ev.data.list_id) {
            // if (ev.data.data.msg.type == 0) {
            //   _chatlist[i].messages.push({
            //     content: ev.data.data.msg.content.text,
            //     date: new Date(ev.data.data.msg.time * 1000),
            //     type: 0
            //   })
            // } else if (ev.data.data.msg.type == 2) {
            //   _chatlist[i].messages.push({
            //     content: ev.data.data.msg.content.url,
            //     w: ev.data.data.msg.content.w,
            //     h: ev.data.data.msg.content.h,
            //     date: new Date(ev.data.data.msg.time * 1000),
            //     type: 2
            //   })
            // }
            console.log(33)
            const itemData = ev.data.data
            _chatlist[i].messages.push({
              content: itemData.msg.content,
              date: new Date(itemData.msg.time * 1000),
              type: itemData.msg.content.type || itemData.type,
              msg_type: itemData.type,
              read: false,
              list_id: ev.data.list_id,
              msg_id: itemData.msg.id,
              userId: itemData.msg.user_info.uid,
              msgItem_type: itemData.msg.content.type || itemData.msg.type,
              self: store.state.user.uid == itemData.msg.user_info.uid,
              user_info: itemData.msg.user_info
            })
            console.log(_chatlist)
            store.dispatch('refreshChatlist', _chatlist)
            return
          }
        }
      } else {
        store.dispatch('fetchCharList', store.state.selectId)
      }

    }
  } else if (ev.action == 'newFriend') {
    if ((openJudge && !isActive) || !openJudge) {
      Notification.requestPermission()
      new Notification('好友申请', {
        body: '你有一条好友申请待查看'
      })
    }
    fetchUserInfo().then(res => {
      const userInfo = res.data.user_info
      store.dispatch('updataUser', {
        ...res.data,
        name: userInfo.username,
        img: photoUrl + userInfo.photo,
        uid: userInfo.id
      })
    })
  }
}

export const websocketsend = (data) => {
  websock.send(data)
}

const websocketonopen = () => {
  const token = localStorage.getItem('token')
  websocketsend(JSON.stringify({
    'action': 'checkToken',
    'data': token
  }))
  websocketsend(JSON.stringify({
    'action': 'ping',
    'data': token
  }))
  //心跳
  // setInterval(() => {
  //   websocketsend(JSON.stringify({
  //     'action': 'ping',
  //     'data': token
  //   }))
  // }, 1000 * 5)
}


const websocketclose = () => {
}

export const initWebSocket = () => {
  websock = new WebSocket(wsUrl);
  websock.onmessage = websocketonmessage;
  websock.onopen = websocketonopen;
  websock.onerror = websocketonerror;
  websock.onclose = websocketclose;
}

const websocketonerror = () => { //连接建立失败重连
  initWebSocket()
}
